import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AlignItems,
  Button,
  ButtonPattern,
  DATE_PICKER_DATE_FORMAT,
  DatePickerField,
  Field,
  FieldMode,
  Flex,
  Grid,
  IconTypes,
  SelectField,
} from '@ac/kiosk-components';

import {
  getDateTimeFormats,
  getDocumentTypeEntities,
  getPropertyConfiguration,
} from 'store/settings/selectors';
import { DateManager } from 'utils/DateManager';
import { mapFieldRenderProps, mapSelectOptions } from 'utils/form';
import { useSubForm } from 'utils/form/subFormApi';
import {
  DocumentFormValues,
  FormProperties,
} from 'views/RegistrationCardEditPersonal/types';

import { useDocumentsFieldsConfiguration } from '../hooks/useDocumentsFieldsConfiguration';

import { DocumentsFormField } from './DocumentsFormFields';

const TEST_SUFFIXES = {
  type: '-type',
  number: '-number',
  expiryDate: '-expiry-date',
  removeButton: '-remove-button',
};

const GRID_TEMPLATE_COLUMNS = 'repeat(12, 1fr)';

interface Props {
  path: [FormProperties.documents, number];
  dataTestSelector?: string;
  removeControlDisabled?: boolean;
  onRemoveClick?: () => void;
}

export const SingleDocumentSubForm = ({
  path,
  dataTestSelector,
  removeControlDisabled,
  onRemoveClick,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const subFormState = useSubForm<DocumentFormValues>(path);
  const fieldsConfiguration = useDocumentsFieldsConfiguration(path);

  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const documentTypes = useSelector(getDocumentTypeEntities);

  const documentOptions = useMemo(
    () => mapSelectOptions(documentTypes, 'description', 'id'),
    [documentTypes]
  );

  const isNewDocument = !subFormState.values?.id;

  return (
    <Flex
      alignItems={AlignItems.center}
      dataTestSelector={dataTestSelector}
      className="gap-lg"
    >
      <Grid
        gridTemplateColumnsSm={GRID_TEMPLATE_COLUMNS}
        className="gap-lg full-width"
      >
        {fieldsConfiguration.type?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.type?.validator}
              valuePath={fieldsConfiguration.type?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.type
                  )}
                  options={documentOptions}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.TYPE'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  modalTitle={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.TYPE_SELECTION_MODAL_TITLE'
                  )}
                  searchable={false}
                  allowClear={!fieldsConfiguration.type?.required}
                  required={fieldsConfiguration.type?.required}
                  readonly={
                    fieldsConfiguration.type?.readonly || !isNewDocument
                  }
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.number?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.number?.validator}
              valuePath={fieldsConfiguration.number?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.number
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.NUMBER'
                  )}
                  placeholder={t('SHARED.FILL')}
                  mode={FieldMode.numeric}
                  required={fieldsConfiguration.number?.required}
                  readonly={fieldsConfiguration.number?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.expiryDate?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.expiryDate?.validator}
              valuePath={fieldsConfiguration.expiryDate?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <DatePickerField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.expiryDate
                  )}
                  placeholder={t('SHARED.FILL')}
                  fieldDisplayFormat={dateTimeFormats?.shortDateFormat}
                  defaultPickerValue={DateManager.getFormattedDate(
                    propertyConfiguration?.businessDate,
                    DATE_PICKER_DATE_FORMAT
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.EXPIRY_DATE'
                  )}
                  allowClear={!fieldsConfiguration.expiryDate?.required}
                  required={fieldsConfiguration.expiryDate?.required}
                  readonly={fieldsConfiguration.expiryDate?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}
      </Grid>

      {!removeControlDisabled && isNewDocument && (
        <Button
          icon={IconTypes.trash}
          pattern={ButtonPattern.tertiary}
          onClick={onRemoveClick}
          dataTestSelector={dataTestSelector?.concat(
            TEST_SUFFIXES.removeButton
          )}
        />
      )}
    </Flex>
  );
};

SingleDocumentSubForm.testSuffixes = TEST_SUFFIXES;
