import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Field, Grid, SelectField } from '@ac/kiosk-components';

import { getLanguagesEntities } from 'store/settings/selectors';
import { mapFieldRenderProps, mapSelectOptions } from 'utils/form';

import { SubFormActionHeader } from '../../SubFormActionHeader/SubFormActionHeader';
import { useAlternateDetailsFieldsConfiguration } from '../hooks/useAlternateDetailsFieldsConfiguration';

import { PersonalDetailsFormField } from './PersonalDetailsFormField';

const TEST_SUFFIXES = {
  actionHeader: '-action-header',
  language: '-language',
  firstName: '-first-name',
  lastName: '-last-name',
};

interface Props {
  isRemoveControlPossible?: boolean;
  dataTestSelector?: string;
  onRemoveClick?: () => void;
}

export const AlternateDetailsSubForm = ({
  isRemoveControlPossible,
  dataTestSelector,
  onRemoveClick,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const fieldsConfiguration = useAlternateDetailsFieldsConfiguration();

  const languages = useSelector(getLanguagesEntities);

  const languageOptions = useMemo(() => {
    return mapSelectOptions(languages, 'name', 'code');
  }, [languages]);

  return (
    <Box dataTestSelector={dataTestSelector}>
      <SubFormActionHeader
        dataTestSelector={dataTestSelector?.concat(TEST_SUFFIXES.actionHeader)}
        title={t('REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.ALT_DETAILS.TITLE')}
        deleteControlEnabled={isRemoveControlPossible}
        onRemoveClick={onRemoveClick}
      />

      <Grid gridTemplateColumnsSm="4fr 4fr 4fr" className="gap-lg">
        {fieldsConfiguration.language?.visible && (
          <Grid.Item>
            <PersonalDetailsFormField
              validate={fieldsConfiguration.language?.validator}
              valuePath={fieldsConfiguration.language?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat('-language')}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.ALT_DETAILS.FIELD.LANGUAGE'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  options={languageOptions}
                  allowClear={!fieldsConfiguration.language?.required}
                  required={fieldsConfiguration.language?.required}
                  readonly={fieldsConfiguration.language?.readonly}
                />
              )}
            </PersonalDetailsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.firstName?.visible && (
          <Grid.Item>
            <PersonalDetailsFormField
              validate={fieldsConfiguration.firstName?.validator}
              valuePath={fieldsConfiguration.firstName?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat('-first-name')}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.ALT_DETAILS.FIELD.ALT_FIRST_NAME'
                  )}
                  placeholder={t('SHARED.FILL')}
                  required={fieldsConfiguration.firstName?.required}
                  readonly={fieldsConfiguration.firstName?.readonly}
                />
              )}
            </PersonalDetailsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.lastName?.visible && (
          <Grid.Item>
            <PersonalDetailsFormField
              validate={fieldsConfiguration.lastName?.validator}
              valuePath={fieldsConfiguration.lastName?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat('-last-name')}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.ALT_DETAILS.FIELD.ALT_LAST_NAME'
                  )}
                  placeholder={t('SHARED.FILL')}
                  required={fieldsConfiguration.lastName?.required}
                  readonly={fieldsConfiguration.lastName?.readonly}
                />
              )}
            </PersonalDetailsFormField>
          </Grid.Item>
        )}
      </Grid>
    </Box>
  );
};

AlternateDetailsSubForm.testSuffixes = TEST_SUFFIXES;
